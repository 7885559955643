import { Dispatch } from "redux";
import { Network } from "@speakap/types";
import { ThunkAction } from "redux-thunk";

import { ApplicationState } from "../reducer";
import { getNetworks, getNetworkRoles } from "../api";

export const FETCH_NETWORKS_REQUEST = "FETCH_NETWORKS_REQUEST";
export const FETCH_NETWORKS_SUCCESS = "FETCH_NETWORKS_SUCCESS";
export const FETCH_NETWORKS_ERROR = "FETCH_NETWORKS_ERROR";
export const SET_ACTIVE_NETWORK = "SET_ACTIVE_NETWORK";

export const FETCH_NETWORK_ROLES_REQUEST = "FETCH_NETWORK_ROLES_REQUEST";
export const FETCH_NETWORK_ROLES_SUCCESS = "FETCH_NETWORK_ROLES_SUCCESS";
export const FETCH_NETWORK_ROLES_ERROR = "FETCH_NETWORK_ROLES_ERROR";

export const FETCH_NETWORKS_PARTIAL_REQUEST = "FETCH_NETWORKS_PARTIAL_REQUEST";
export const FETCH_NETWORKS_PARTIAL_SUCCESS = "FETCH_NETWORKS_PARTIAL_SUCCESS";
export const FETCH_NETWORKS_PARTIAL_ERROR = "FETCH_NETWORKS_PARTIAL_ERROR";

interface FetchNetworksPartialRequestAction {
    type: typeof FETCH_NETWORKS_PARTIAL_REQUEST;
}

interface FetchNetworksPartialSuccessAction {
    type: typeof FETCH_NETWORKS_PARTIAL_SUCCESS;
    payload: Array<Network>;
    limit: number;
    offset: number;
    totalItems: number;
}

interface FetchNetworksPartialErrorAction {
    type: typeof FETCH_NETWORKS_PARTIAL_ERROR;
}

interface FetchNetworksRequestAction {
    type: typeof FETCH_NETWORKS_REQUEST;
}

interface FetchNetworksSuccessAction {
    type: typeof FETCH_NETWORKS_SUCCESS;
    payload: Array<Network>;
    limit: number;
    offset: number;
    totalItems: number;
}

interface FetchNetworksErrorAction {
    type: typeof FETCH_NETWORKS_ERROR;
}

interface SetActiveNetworkAction {
    payload: { networkId: string; networkName: string; networkSubdomain: string };
    type: typeof SET_ACTIVE_NETWORK;
}
interface FetchNetworkRolesRequestAction {
    type: typeof FETCH_NETWORK_ROLES_REQUEST;
}

interface FetchNetworkRolesSuccessAction {
    type: typeof FETCH_NETWORK_ROLES_SUCCESS;
    payload: Array<{ id: string; name: string }>;
}

interface FetchNetworkRolesErrorAction {
    type: typeof FETCH_NETWORK_ROLES_ERROR;
}

export type NetworksActions =
    | FetchNetworksSuccessAction
    | FetchNetworksRequestAction
    | FetchNetworksErrorAction
    | SetActiveNetworkAction
    | FetchNetworkRolesSuccessAction
    | FetchNetworkRolesRequestAction
    | FetchNetworkRolesErrorAction
    | FetchNetworksPartialRequestAction
    | FetchNetworksPartialSuccessAction
    | FetchNetworksPartialErrorAction;

type Thunk<Result> = ThunkAction<Result, ApplicationState, void, NetworksActions>;

const BATCH_SIZE = 50;

export const fetchNetworks = (): ThunkAction<
    Promise<void>,
    ApplicationState,
    void,
    NetworksActions
> => async (dispatch: Dispatch) => {
    dispatch({ type: FETCH_NETWORKS_REQUEST });

    try {
        let allNetworks: Array<Network> = [];
        let offset = 0;
        let hasMoreNetworks = true;

        while (hasMoreNetworks) {
            const response = await getNetworks(BATCH_SIZE, offset);
            const newNetworks = response.networks;

            allNetworks = [...allNetworks, ...newNetworks];
            offset += BATCH_SIZE;

            dispatch({
                limit: BATCH_SIZE,
                offset,
                payload: allNetworks,
                type: FETCH_NETWORKS_PARTIAL_SUCCESS,
            });

            if (newNetworks.length < BATCH_SIZE) {
                hasMoreNetworks = false;
            }
        }

        dispatch({
            limit: BATCH_SIZE,
            offset,
            payload: allNetworks,
            type: FETCH_NETWORKS_SUCCESS,
        });
    } catch (error) {
        dispatch({
            message: error instanceof Error ? error.message : "Error",
            type: FETCH_NETWORKS_ERROR,
        });
    }
};

export const fetchNetworkRoles = (networkId: string): Thunk<Promise<void>> => async dispatch => {
    try {
        dispatch({ type: FETCH_NETWORK_ROLES_REQUEST });

        const response = await getNetworkRoles(networkId);
        dispatch({ payload: response, type: FETCH_NETWORK_ROLES_SUCCESS });
    } catch (error) {
        dispatch({
            message: error instanceof Error ? error.message : "Error",
            type: FETCH_NETWORK_ROLES_ERROR,
        });
    }
};

export const setActiveNetwork = (
    networkId: string,
    networkName: string,
    networkSubdomain: string,
): SetActiveNetworkAction => ({
    payload: { networkId, networkName, networkSubdomain },
    type: SET_ACTIVE_NETWORK,
});
